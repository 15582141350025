<template>
  <div :class="$style['order-descr']">
    <div :class="$style['order-descr__main']">
      <div :class="$style['order-descr__main__descr']">
        <div :class="$style['game_info']">
          <img
            :src="order.game.logo"
            alt=""
          />
          <div :class="$style['game_info__descr']">
            <div :class="$style['game_info__descr__title']">
              {{ order?.game.title }}
            </div>
            <div :class="$style['game_info__descr__date']">
              {{ getHumanDateTime(order?.created.toString()) }}
            </div>
          </div>
        </div>
        <div :class="$style['order-descr__main__descr__id']">
          ID: {{ order?.id }}
        </div>
      </div>
      <RejectReason
        v-if="order.rejectReason"
        :reason="order.rejectReason"
      />
      <div :class="$style['order-descr__main__products']">
        <ProductRow
          v-for="(product, i) in order.orderProducts"
          :key="i"
          :product="product"
        />
      </div>
    </div>
    <div :class="$style['order-descr__support']">
      <div :class="$style['order-descr__support__controls']">
        <SecondaryButton
          size="l"
          :class="$style['order-descr__support__controls__btn']"
          @click="openSupport"
        >
          <IconSupport />
          <span :class="$style['btn-title']">{{ t(`topup.support`) }}</span>
        </SecondaryButton>
        <SecondaryButton
          v-if="order.status == 'done' || order.status == 'failed'"
          size="l"
          :class="$style['order-descr__support__controls__btn']"
          @click="repeatOrder(order)"
        >
          <IconRefresh />
          <span :class="$style['btn-title']">{{ t("topup.repeat") }}</span>
        </SecondaryButton>
        <SecondaryButton
          v-if="order.status == 'waiting_for_payment' && order.isPaymentActive"
          size="l"
          :class="$style['order-descr__support__controls__btn']"
          @click="productsStore.continueOrder(order.id)"
        >
          <span :class="$style['btn-title']">{{ t("topup.continue") }}</span>
          <IconChevronRight />
        </SecondaryButton>
      </div>
      <Heading3> {{ toLocalNumber(+order.total) }} ₽ </Heading3>
    </div>
  </div>
  <transition name="popup">
    <PopupContainer
      v-if="showPopupContainer"
      @hide="handleHide"
    >
      <FormOrderConfirm
        v-if="productsStore.showOrderConfirmForm"
        @hide="handleHide"
      />
      <FormOrderUserData
        v-if="productsStore.showUserDataForm"
        @hide="handleHide"
      />
    </PopupContainer>
  </transition>
</template>

<script setup lang="ts">
import { getHumanDateTime } from "~/utils/dates/human_date.ts";
import ProductRow from "~/components/Profile/OrderHistory/ProductRow.vue";
import Heading3 from "~/components/Shared/Heading/Heading3.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import RejectReason from "~/components/Profile/RejectReason.vue";
import FormOrderConfirm from "~/components/Forms/FormOrderConfirm.vue";
import PopupContainer from "~/components/Shared/Popup/PopupContainer.vue";
import FormOrderUserData from "~/components/Forms/FormOrderUserData.vue";
import {
  handleHide,
  repeatOrder,
} from "~/components/Profile/OrderHistory/repeatOrder.ts";
import type { OrderHistory } from "~/api_gen";

const props = defineProps<{
  order: OrderHistory;
}>();

const { order } = toRefs(props);

const { t } = useI18n();
const productsStore = useProductsStore();
const showPopupContainer = computed(
  () => productsStore.showOrderConfirmForm || productsStore.showUserDataForm,
);
const openSupport = () => {
  if (window.TalkMe) {
    window.TalkMe("openSupport");
  }
};
</script>

<style module lang="scss">
.order-descr {
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-4xl);
  border-radius: var(--radius-6xl);
  background: #191919;
  position: sticky;
  top: 112px;

  @media screen and (max-width: $tablet) {
    max-width: unset;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-4xl);
    border-bottom: 1px solid var(--general-transparent-mid-25);
    padding-bottom: var(--spacing-2xl);
    width: 100%;

    &__descr {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      &__id {
        font-size: 16px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.08px;
      }
      .game_info {
        display: flex;
        align-items: center;
        gap: var(--spacing-2lg);

        img {
          width: 80px;
          height: 80px;
          border-radius: var(--radius-4xl);
        }

        &__descr {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-4xs);
          &__title {
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.72px;
          }

          &__date {
            color: var(--general-transparent-light-100);
            opacity: 0.4;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.014px;
          }
        }
      }
    }

    &__products {
      width: 100%;
      max-height: 356px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-sm);
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
    }
  }

  &__support {
    display: flex;
    padding-top: var(--spacing-4xl);
    gap: var(--spacing-4xl);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    h3 {
      flex-shrink: 0;
    }

    &__controls {
      display: flex;
      gap: var(--spacing-2xs);

      .btn-title {
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }
  }
}
</style>
