<template>
  <Popup :class="$style['form-order-confirm']">
    <div :class="$style['order-descr']">
      <div :class="$style['order-descr__main']">
        <div :class="[$style['order-descr__main__item']]">
          <div :class="$style['order-descr__main__descr']">
            <div :class="$style['game_info']">
              <img
                :src="order.game.logo"
                alt=""
              />
              <div :class="$style['game_info__descr']">
                <div :class="$style['game_info__descr__title']">
                  {{ order?.game.title }}
                </div>
                <div :class="$style['game_info__descr__date']">
                  <span
                    >{{ `${order.orderProducts.length} ${productCase}` }}
                  </span>
                  <span>•</span>
                  <span>
                    {{ getHumanDateTime(order?.created.toString()) }}
                  </span>
                </div>
              </div>
            </div>
            <PopupButton
              size="s"
              :class="[$style['btn-right']]"
              @click="$emit('hide')"
            >
              <IconCross />
            </PopupButton>
          </div>
          <RejectReason
            v-if="order.rejectReason"
            :class="$style['reject-reason']"
            :reason="order.rejectReason"
          />
          <div :class="$style['order-descr__main__total']">
            <Heading4>
              {{ t("topup.total") }}
            </Heading4>
            <Heading3> {{ toLocalNumber(+order.total) }} ₽</Heading3>
          </div>
          <div :class="$style['order-descr__main__controls']">
            <div :class="$style['order-descr__main__controls__item']">
              <SecondaryButton
                size="l"
                :class="$style['order-descr__main__controls__btn']"
                @click="openSupport"
              >
                <IconSupport />
              </SecondaryButton>
              <div :class="$style['order-descr__main__controls__title']">
                {{ t(`topup.support`) }}
              </div>
            </div>
            <div :class="$style['order-descr__main__controls__item']">
              <SecondaryButton
                size="l"
                :class="$style['order-descr__main__controls__btn']"
                @click="repeatOrder(order)"
              >
                <IconRefresh />
              </SecondaryButton>
              <div :class="$style['order-descr__main__controls__title']">
                {{ t(`topup.repeat`) }}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[
            $style['order-descr__main__products'],
            $style['order-descr__main__item'],
          ]"
        >
          <Heading3>{{ t("topup.order_content") }}</Heading3>
          <div :class="$style['order-descr__main__products__list']">
            <ProductRow
              v-for="(product, i) in order.orderProducts"
              :key="i"
              :product="product"
            />
          </div>
        </div>
        <div
          :class="[
            $style['order-descr__main__id'],
            $style['order-descr__main__item'],
          ]"
        >
          ID: {{ order?.id }}
        </div>
      </div>
    </div>
  </Popup>
  <transition name="popup">
    <PopupContainer
      v-if="showPopupContainer"
      @hide="handleHide"
    >
      <FormOrderConfirm
        v-if="productsStore.showOrderConfirmForm"
        @hide="handleHide"
      />
      <FormOrderUserData
        v-if="productsStore.showUserDataForm"
        @hide="handleHide"
      />
    </PopupContainer>
  </transition>
</template>

<script setup lang="ts">
import Popup from "~/components/Shared/Popup/Popup.vue";
import ProductRow from "~/components/Profile/OrderHistory/ProductRow.vue";
import { getHumanDateTime } from "~/utils/dates/human_date.ts";
import PopupButton from "~/components/Shared/Button/PopupButton.vue";
import { usePluralize } from "~/composables/usePluralize.ts";
import Heading3 from "~/components/Shared/Heading/Heading3.vue";
import Heading4 from "~/components/Shared/Heading/Heading4.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import RejectReason from "~/components/Profile/RejectReason.vue";
import FormOrderConfirm from "~/components/Forms/FormOrderConfirm.vue";
import PopupContainer from "~/components/Shared/Popup/PopupContainer.vue";
import FormOrderUserData from "~/components/Forms/FormOrderUserData.vue";
import {
  handleHide,
  repeatOrder,
} from "~/components/Profile/OrderHistory/repeatOrder.ts";
import type { OrderHistory } from "~/api_gen";

const props = defineProps<{
  order: OrderHistory;
}>();

const { order } = toRefs(props);
const productsStore = useProductsStore();
defineEmits<{
  (e: "hide"): void;
}>();
const { t } = useI18n();

const showPopupContainer = computed(
  () => productsStore.showOrderConfirmForm || productsStore.showUserDataForm,
);

const productCase = computed(() => {
  return usePluralize(order.value.orderProducts.length, [
    t("topup.products.one"),
    t("topup.products.two"),
    t("topup.products.three"),
  ]);
});

const openSupport = () => {
  if (window.TalkMe) {
    window.TalkMe("openSupport");
  }
};
</script>

<style module lang="scss">
.form-order-confirm {
  padding: 0;
}

.order-descr {
  max-height: calc(100dvh - 70px);
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-4xl) var(--radius-4xl) 0 0;
  background: var(--general-transparent-dark-100-temp);
  padding-bottom: var(--spacing-7xl);

  @media screen and (max-width: $tablet) {
    max-width: unset;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xs);
    width: 100%;

    .reject-reason {
      margin: var(--spacing-md) var(--spacing-xl);
    }

    &__descr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      padding: var(--spacing-2lg) var(--spacing-2lg) var(--spacing-2lg)
        var(--spacing-3xl);

      .btn-right svg {
        width: 14px;
        height: 14px;
      }

      &__id {
        font-size: 16px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.08px;
      }

      .game_info {
        display: flex;
        align-items: center;
        gap: var(--spacing-md);

        img {
          width: 54px;
          aspect-ratio: 1;
          border-radius: var(--radius-4xl);
        }

        &__descr {
          display: flex;
          flex-direction: column;
          gap: 0;

          &__title {
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.72px;
          }

          &__date {
            display: flex;
            gap: var(--spacing-4xs);
            color: var(--general-transparent-light-100);
            opacity: 0.4;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.014px;
          }
        }
      }
    }

    &__controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-4xl);
      padding: var(--spacing-xl) 0 var(--spacing-3xl);

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-sm);
      }

      &__btn {
        display: flex;
        width: 54px;
        height: 54px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xs);
        border-radius: var(--radius-max, 100px);
        background: var(--general-transparent-light-6);
      }

      &__title {
        color: var(--general-transparent-light-35);
        font-size: 12px;
        font-weight: 500;
        line-height: 110%;
        letter-spacing: -0.06px;
      }
    }

    &__item {
      border-radius: var(--radius-5xl);
      background: var(--general-underlay-3-lvl);
      width: 100%;
    }

    &__products {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-lg);
      padding: var(--spacing-4xl);

      &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.72px;
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-4xl);
        width: 100%;
      }
    }

    &__total {
      width: 100%;
      display: flex;
      padding: var(--spacing-sm) var(--spacing-4xl) var(--spacing-2lg);
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--general-transparent-mid-25);
    }

    &__id {
      display: flex;
      padding: var(--spacing-2lg) var(--spacing-4xl);
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
}
</style>
