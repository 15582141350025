import type { OrderHistory } from "~/api_gen";
import { getGameDataFromOrder } from "~/components/Profile/OrderHistory/getGameData.ts";

export const repeatOrder = async (order: OrderHistory) => {
  if (await getGameDataFromOrder(order)) {
    const productsStore = useProductsStore();
    document.documentElement.style.overflow = "hidden";
    productsStore.showOrderConfirmForm = true;
    productsStore.repeatedOrderId = order.id;
    if (order.resourcetype === "SteamOrder") {
      productsStore.steamAmount = Number(order.total);
      productsStore.steamAccountId = order.steamAccountId;
    }
    if (order.resourcetype === "MooGoldOrder") {
      productsStore.gameServerId = order.moogoldServer;
      productsStore.moogoldInputValueId = order.moogoldUserId;
    }
    if (order.resourcetype === "StandoffOrder") {
      productsStore.standoffInputValueId = order.gameAccountId;
      productsStore.defaultSteamAvatar = order.gameAvatar;
    }
    if ("email" in order && typeof order.email === "string") {
      productsStore.inputValueMail = order.email;
    }
  }
};

export const handleHide = () => {
  const productsStore = useProductsStore();
  productsStore.showOrderConfirmForm = false;
  productsStore.showUserDataForm = false;
  document.documentElement.style.overflow = "";
};
